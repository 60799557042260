import React from 'react';

import Utils from './utils.js';

class Tracker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification: null,
            updated: false,
            found: false
        };

        this.isShowingNotification = false;
    }

    componentDidMount() {
         this.props.model.on('error', this.genericErrorHandler, this);

        this.props.model.on('found', this.characterFoundHandler, this);
        this.props.model.on('not-found', this.characterNotFoundHandler, this);

        if (this.props.notificationModel) {
            this.props.notificationModel.notificationQueue.on('add', this.showNotification, this);
        }

        this.props.onUpdateTracker();
    }

    componentWillUnmount() {
        this.props.model.off(null, null, this);

        if (this.props.notificationModel) {
            this.props.notificationModel.notificationQueue.off(null, null, this);
        }
    }

    genericErrorHandler() {
        this.setState({
            updated: true,
            found: false
        });
    }

    characterFoundHandler() {
        this.setState({
            updated: true,
            found: true,
            lastUpdate: new Date().getTime()
        });
    }

    characterNotFoundHandler() {
        this.setState({
            updated: true,
            found: false,
            lastUpdate: new Date().getTime()
        });
    }

    showNotification() {
        if (!this.isShowingNotification) {
            this.isShowingNotification = true;

            let notification = this.props.notificationModel.notificationQueue.shift();

            if (notification) {
                this.setState({ notification });
                setTimeout(() => {
                    this.isShowingNotification = false;
                    this.setState({ notification: null });
                    this.showNotification();
                }, 20000);
            } else {
                this.isShowingNotification = false;
            }
        }
    }

    renderRankChange() {
        let diff = this.props.model.get('previous_rank') === null ? null : this.props.model.get('previous_rank') - this.props.model.get('rank');

        /*
        if ((!this.props.model.get('previous_rank') || Math.abs(diff) >= 2500) && this.props.model.get('rank')) {
            return (
                <span className="mini digit arrow-container">
                    <span className="green arrow" />
                    <span>(NEW)</span>
                </span>
            );
        }
         */

        if (diff > 0) {
            return (
                <span className="mini digit arrow-container">
                    <span className="green arrow" />
                    <span>(+{diff})</span>
                </span>
            );
        } else if (diff < 0) {
            return (
                <span className="mini digit arrow-container">
                    <span className="red arrow" />
                    <span >(-{Math.abs(diff)})</span>
                </span>
            )
        } else {
            return (
                <span className="mini digit">-</span>
            );
        }
    }

    renderExperiencePerHour(xph = null) {
        let xp = 0;

        if(xph !== null) {
            if(xph) {
                return (
                    <span className={'xp-per-hour'}>
                        {Utils.renderExp(xph, true)}
                    </span>
                );
            } else {
                return (
                    <span>{'-'}</span>
                );
            }
        } else if(!xph && this.getFlooredXPH(this.props.model)) {
            xp = this.getFlooredXPH(this.props.model);
            return (
                <span className={'xp-per-hour'}>
                    {Utils.renderExp(xp, true)}
                </span>
            );
        } else {
            return (
                <span>{'-'}</span>
            );
        }
    }

    getFlooredXPH(model) {
        return Math.floor(model.get('experience_per_hour'));
    }

    toggleXPH() {
        this.props.model.xph = !this.props.model.xph;

        this.props.onUpdateTracker();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    renderPoints(model) {
        return (
            <span style={{ marginLeft: '4px'}}>{this.renderPointDiff(this.props.model.get('points'), model.get('points'))}</span>
        )
    }

    renderPointDiff(a, b) {
        if (a < b) {
            return (
                <span className="same-points">
                    (<span className="ahead-points">{b - a}</span>)
                </span>
            );
        } else if (b < a) {
            return (
                <span className="same-points">
                    (<span className="behind-points">{b - a}</span>)
                </span>
            );
        } else {
            return (
                <span className="same-points">
                    (<span>{b - a}</span>)
                </span>
            );
        }
    }

    renderList() {
        if (!this.props.model.list.length) {
            return null;
        }

        let rankType = 'rank';

        let title = (
            <tr key={new Date().getTime()}>
                <th colSpan="5">
                    <hr style={{ border: '0', height: '1px', background: '#333', margin: '0 0 10px 0'}}/>
                </th>
            </tr>
        );

        let rows = this.props.model.list.models.map((model, idx) => {
            let classes = [];

            if(model.get('name') !== this.props.model.characterParam) {
                classes.push('blur');
            }

            let diff = parseInt(model.get('experience')) - parseInt(this.props.model.get('experience'));

            if (!this.props.model.xph) {
                if (diff > 0) {
                    classes.push('ahead');
                } else if (diff < 0) {
                    classes.push('behind');
                }
            }

            return (
                <tr key={idx + 1} className={classes.join(' ')}>
                    <td colSpan="5">
                        <span className="rank">{model.get(rankType)}.</span>
                        <span className={['name', (model.get('retired') || model.get('dead')) ? 'dead' : null].join(' ')}>
                            <span>{model.get('name')}</span>
                        </span>
                        <span className="exp">
                            {
                                this.props.model.xph ?
                                    this.renderExperiencePerHour(this.getFlooredXPH(model)) :
                                    Utils.renderExp(diff)
                            }
                            {this.props.model.objectiveParam && this.props.model.objectiveParam === 'POINTS' ? this.renderPoints(model) : null}
                        </span>
                    </td>
                </tr>
            );
        });

        return [
            title,
            rows
        ];
    }

    onClose() {
        this.props.onUpdateTracker({ reset: true });

        this.setState({
            updated: false
        });
    }

    getBehindRank() {
        return 'Rank ' + (parseInt( this.props.model.get('rank')) + 1);
    }

    getAheadRank() {
        if (parseInt(this.props.model.get('rank')) === 1) {
            return 'Rank 1';
        }

        return 'Rank ' + (parseInt(this.props.model.get('rank')) - 1);
    }

    renderSkull() {
        return <span className="skull" />;
    }

    renderTrackerTitle() {
        if (this.props.model.objectiveParam && this.props.model.objectiveParam === 'POINTS') {
            return 'Level ' + this.props.model.get('level') + '+' + this.props.model.get('points') + ' ' + this.props.model.get('class');
        } else {
            return 'Level ' + this.props.model.get('level') + ' ' + this.props.model.get('class');
        }
    }

    renderClassRank() {
        if (this.props.showClassRankInsteadOfAscendancyRank) {
            return this.props.model.get('class_rank');
        }

        return this.props.model.get('ascendancy_rank') || this.props.model.get('class_rank') || '-';
    }

    renderMiniTracker() {
        return [
            <tbody>
                <tr className="padless">
                    <td className="center value yellow" colSpan="3">
                        {this.renderTrackerTitle()}
                    </td>
                </tr>
                <tr className="padless">
                    <th width="32%" className="center chunky">{this.props.showClassRankInsteadOfAscendancyRank ? 'CR' : 'AR'}</th>
                    <th width="36%" className="center chunky">Rank</th>
                    <th width="32%" className="center chunky">Change</th>
                </tr>
                <tr className="padless">
                    <td className="center value midly yellow">{this.renderClassRank()}</td>
                    <td className="center value bigly yellow">{this.props.model.get('rank') || '-'}</td>
                    <td className="center value midly">{this.renderRankChange()}</td>
                </tr>
            </tbody>
        ];
    }

    renderMediumTracker() {
        return [
            <tbody>
            <tr className="padless">
                <td className="center value yellow" colSpan="3">
                    {this.renderTrackerTitle()}
                </td>
            </tr>
            <tr className="padless">
                <th width="32%" className="center chunky">{this.props.showClassRankInsteadOfAscendancyRank ? 'CR' : 'AR'}</th>
                <th width="36%" className="center chunky">Rank</th>
                <th width="32%" className="center chunky">Change</th>
            </tr>
            <tr className="padless">
                <td className="center value midly yellow">{this.renderClassRank()}</td>
                <td className="center value bigly yellow">{this.props.model.get('rank') || '-'}</td>
                <td className="center value midly">{this.renderRankChange()}</td>
            </tr>
            <tr className="padless">
                <th width="32%" className="center chunky">{this.getBehindRank()}</th>
                <th width="36%" className="center chunky">{'XPH'}</th>
                <th width="32%" className="center chunky">{this.getAheadRank()}</th>
            </tr>
            <tr className="padless">
                <td className="center value midly behind yellow">{this.props.model.getBehindXP()}</td>
                <td className="center value midly xp-per-hour">{this.props.model.get('dead') ? this.renderSkull() : this.props.model.getXPH()}</td>
                <td className="center value midly ahead yellow">{this.props.model.getAheadXP()}</td>
            </tr>
            </tbody>
        ];
    }

    renderFullTracker() {
        return [
            <tbody key={Math.random() + 1}>
                <tr className="padless">
                    <th width="22%" className="center chunky">
                        {this.props.model.objectiveParam && this.props.model.objectiveParam === 'POINTS' ? 'Points' : 'Level'}
                    </th>
                    <th width="17%" className="center chunky">Rank Change</th>
                    <th width="22%" className="center chunky">Rank</th>
                    <th width="17%" className="center chunky">{this.props.showClassRankInsteadOfAscendancyRank ? 'CR' : 'AR'}</th>
                    <th width="22%" className="center chunky">XPH</th>
                </tr>
                <tr className="padless">
                    <td className="center value midly yellow">{this.renderFullLadderKeyProperty()}</td>
                    <td className="center value midly">{this.renderRankChange()}</td>
                    <td className="center value bigly yellow">{this.props.model.get('rank') || '-'}</td>
                    <td className="center value midly yellow">{this.renderClassRank()}</td>
                    <td className="center value">{this.props.model.get('dead') ? this.renderSkull() : this.renderExperiencePerHour()}</td>
                </tr>
            </tbody>,
            <tbody key={Math.random() + 2}>
                {this.renderList()}
            </tbody>
        ];
    }

    renderFullLadderKeyProperty() {
        if (this.props.model.objectiveParam && this.props.model.objectiveParam === 'POINTS') {
            return parseInt(this.props.model.get('points')) + parseInt(this.props.model.get('level'));
        } else {
            return this.props.model.get('level');
        }
    }

    renderNoTarget() {
        return (
            <tbody key={Math.random() + 1}>
                <tr>
                    <td colSpan="5">
                        <div className="character-not-found">
                            <div>Could not find</div>
                            <div style={{ lineHeight: '18px', color: '#FFCC33' }}>{this.props.model.characterParam}</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    renderNotification() {
        if (!this.state.notification) {
            return null;
        }

        return (
            <div className="tracker-notification">
                <span className="message">{this.composeNotificationMessage(this.state.notification)}</span>
            </div>
        );
    }

    composeNotificationMessage(item) {
        return (
            <span key={item.get('id')}>
                <span className="highlight">{item.get('account')} </span>
                <span>has found a</span>
                <span className="highlight item-found">
                    {item.get('links') === 6 ? <span>6L </span> : null}
                    {this.itemDescription(item)}
                </span>
                <span>{item.get('class')}, </span>
                <span>Level </span>
                <span className="highlight">{item.get('level')}, </span>
                <span>Rank </span>
                <span className="highlight">{item.get('rank')}</span>
            </span>
        );
    }

    itemDescription(item) {
        if (item.get('rarity') === 'UNIQUE') return item.get('name');
        else return 'item';
    }

    renderTracker() {
        let trackerContents = !this.props.model.characterParam || !this.state.found ? this.renderNoTarget() :
            this.props.model.sizeParam === 1 ? this.renderMiniTracker() :
                this.props.model.sizeParam === 2 ? this.renderMediumTracker() :
                    this.renderFullTracker();

        return (
            <table>{trackerContents}</table>
        );
    }

    render() {
        if (!this.state.updated) {
            return <div>Booting up ...</div>;
        }

        let classes = ['tracker'];

        if (this.props.fixed) {
            classes.push('fixed');
        }

        if (this.props.model.sizeParam === 1) classes.push('small');
        else if (this.props.model.sizeParam === 2) classes.push('medium');
        else classes.push('large');

        return (
            <div className={classes.join(' ')}>
                {this.renderTracker()}
                {this.renderNotification()}
            </div>
        );
    }
}

export default Tracker;