export default {
	extractCookieEventData(cookie) {
		if (!cookie) {
			return null;
		}

		let arr = cookie.split(';');

		for(let idx in arr) {
			if (arr.hasOwnProperty(idx) && arr[idx].indexOf('event=') >= 0) {
				let pair = arr[idx].split('=');
				if (pair.length === 2) {
					return pair[1];
				}
			}
		}

		return null;
	},

	validGraphQuery(collection) {
		return !(collection.mode === 'level' &&
			collection.characters.length > 2 &&
			(parseInt(collection.filterModel.get('max'), 10) > 95 ||
				parseInt(collection.filterModel.get('min'), 10) > 94));
	},

	getBaseClasses() {
		return ['Scion', 'Marauder', 'Templar', 'Duelist', 'Ranger', 'Shadow', 'Witch'];
	},

	getMilliseconds(timeString) {
		let chunks = timeString.split(':');

		let days = 0,
			hours = 0,
			minutes = 0,
			seconds = 0,
			milliseconds,
			secondSplit;

		if (chunks.length === 2) {
			minutes = parseInt(chunks[0]);
			secondSplit = chunks[1].split('.');
		} else if (chunks.length === 3) {
			hours = parseInt(chunks[0]);
			minutes = parseInt(chunks[1]);
			secondSplit = chunks[2].split('.');
		} else if (chunks.length === 4) {
			days = parseInt(chunks[0]);
			hours = parseInt(chunks[1]);
			minutes = parseInt(chunks[2]);
			secondSplit = chunks[3].split('.');
		} else {
			return false;
		}

		// Since we manage seconds and milliseconds the same no matter the amount of chunks, we can do it out here.
		seconds = parseInt(secondSplit[0]);
		milliseconds = secondSplit[1] ? secondSplit[1] : '0';

		// milliseconds should not be more than 3 digits
		if (milliseconds && milliseconds.length > 3) {
			return false;
		}

		// Returns time in milliseconds
		return (days * 24 * 60 * 60 +
			hours * 60 * 60 +
			minutes * 60 +
			seconds) * 1000 + parseInt(milliseconds);
	},

	extractCookieRacingSeasonData(cookie)  {
		if (!cookie) {
			return null;
		}

		let arr = cookie.split(';');

		for(let idx in arr) {
			if (arr.hasOwnProperty(idx) && arr[idx].indexOf('season=') >= 0) {
				let pair = arr[idx].split('=');
				if (pair.length === 2) {
					return pair[1];
				}
			}
		}

		return null;
	},

	getItemRarity(model) {
		switch(model.get('frameType')) {
			case 0: return 'common';
			case 1: return 'uncommon';
			case 2: return 'rare';
			case 3: return 'unique';
			case 4: return 'gem';
			default: return '';
		}
	},

	convertSizeToNumber(size) {
		return size === 'small' ? 1 : size === 'medium' ? 2 : size === 'large' ? 3 : null;
	},

	renderClassClasses(className, hasChanged = false) {
		let classes = ['class'],
			classType = className ? className.toLowerCase() : 'no-class';

		if (hasChanged) {
			classes.push('ascendancy-changed');
		}

		switch(classType) {
			case 'occultist':
			case 'elementalist':
			case 'necromancer':
				classes.push('witch');
				break;
			case 'inquisitor':
			case 'hierophant':
			case 'guardian':
				classes.push('templar');
				break;
			case 'juggernaut':
			case 'chieftain':
			case 'berserker':
				classes.push('marauder');
				break;
			case 'gladiator':
			case 'champion':
			case 'slayer':
				classes.push('duelist');
				break;
			case 'deadeye':
			case 'raider':
			case 'pathfinder':
				classes.push('ranger');
				break;
			case 'assassin':
			case 'saboteur':
			case 'trickster':
				classes.push('shadow');
				break;
			case 'ascendant':
				classes.push('scion');
				break;
			default:
				classes.push(classType);
				break;
		}

		return classes.join(' ');
	},

	isDelveLadderMode(mode) {
		return mode === 'depth' || mode === 'depthsolo';
	},

	renderFullExp(experience) {
		return parseInt(experience).toLocaleString();
	},

	renderExp(experience, ignoreOperators = false) {
		let negative = experience < 0,
			xp = Math.abs(experience);

		if(xp >= 1000000000) {
			return (ignoreOperators ? '' : ((negative ? '-' : ''))) + (xp / 1000000000).toFixed(2) + 'B';
		} else if(xp >= 1000000) {
			return (ignoreOperators ? '' : ((negative ? '-' : ''))) + (xp / 1000000).toFixed(2) + 'M';
		} else if(xp >= 1000) {
			return (ignoreOperators ? '' : ((negative ? '-' : ''))) + (xp / 1000).toFixed(2) + 'K';
		} else if(xp === 0) {
			return '0';
		} else {
			return (ignoreOperators ? '' : ((negative ? '-' : ''))) + xp;
		}
	},

	getTimeWithMilliseconds(time, includeMilliseconds = true) {
		if (!time || time === '0') return '-';

		let str = this.calculateDuration(Math.floor(time / 1000));

		if (!str) return '??';

		if (includeMilliseconds) {
			str += '.' + (time + '').substr(-3);
		}

		return str;
	},

	calculateDuration(duration) {
		let arr = [];

		let secondsPerDay = 24 * 60 * 60;
		let secondsPerHour = 60 * 60;
		let secondsPerMinute = 60;

		let days = Math.floor(duration / secondsPerDay);
		duration -= secondsPerDay * days;

		let hours = Math.floor(duration / secondsPerHour);
		duration -= secondsPerHour * hours;

		let minutes = Math.floor(duration / secondsPerMinute);
		duration -= secondsPerMinute * minutes;

		let seconds = duration;

		if (!days && !hours && !minutes && !seconds) return null;

		if (days > 0) {
			arr.push(days.toString());
		}

		if (hours > 0) {
			arr.push(("0" + hours.toString()).slice(-2));
		}

		arr.push(("0" + minutes.toString()).slice(-2));
		arr.push(("0" + seconds.toString()).slice(-2));

		return arr.join(':');
	},

	renderAwardDescription(award) {
		if (award.type === 'MAX_DEPTH' || award.type === 'SOLO_DEPTH') {
			return award.character + ', ' + award.level + ' ' + award.class + ', Delve Depth: ' + award.max_depth + ', Solo Delve Depth: ' + award.solo_depth;
		} else if(award.type === 'ASPIRANT') {
			return award.character + ' character(s) with a total level score of ' + award.level + '.';
		} else if(award.type === 'REBEL') {
			return award.character + ' leveled to 100 without ever ascending.';
		} else {
			return award.character + ', ' + award.level + ' ' + award.class;
		}
	},

	renderGrandAwardDescription(award, year) {
		const isCurrentYear = parseInt(year, 10) === new Date().getFullYear();

		if (isCurrentYear) {
			return 'This player is the current Grand ' + (award.substr(0, 1).toUpperCase() + award.substr(1));
		} else {
			return 'This player was awarded the Grand ' + (award.substr(0, 1).toUpperCase() + award.substr(1)) + ' title in ' + year;
		}
	},

	getCurrentPath(path) {
		if (!path) return null;

		let arr = path.split('/');

		return arr[0];
	},

	getNextPath(path, cutNextSlash = false) {
		if (!path) return null;

		let arr = path.split('/');

		if (arr.length <= 1) return null;

		let slice = arr.slice(1);

		return slice.length > 0 ?
			cutNextSlash ? slice[0] : slice.join('/') :
			null;
	}
};