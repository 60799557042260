import React from 'react';
import Backbone from 'backbone';

class DelveTracker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updated: false
        };
    }

    componentDidMount() {
        this.updateModel();

        setInterval(() => {
            this.updateModel();
        }, 60000);
    }

    updateModel() {
        this.props.model.url = 'https://api.poe-racing.com/api/ladder/depth/' + encodeURIComponent(this.props.mainModel.eventParam) + '/track/' + encodeURIComponent(this.props.mainModel.characterParam);

        this.props.model.fetch().then(() => {
            this.setState({
                updated: new Date().getTime()
            });
        });
    }

    renderTrackerTitle() {
        return 'Level ' + this.props.model.get('level') + ' ' + this.props.model.get('class');
    }

    getBehindRank() {
        return 'Rank ' + (parseInt( this.props.model.get('rank')) + 1);
    }

    getAheadRank() {
        if (parseInt(this.props.model.get('rank')) === 1) {
            return 'Rank 1';
        }

        return 'Rank ' + (parseInt(this.props.model.get('rank')) - 1);
    }

    renderSkull() {
        return <span className="skull" />;
    }

    renderClassRank() {
        return this.props.model.get('ascendancy_rank') || '-';
    }

    renderRankChange() {
        let diff = this.props.model.get('previous_rank') === null ? null : this.props.model.get('previous_rank') - this.props.model.get('rank');

        if (diff > 0) {
            return (
                <span className="mini digit arrow-container">
                    <span className="green arrow" />
                    <span>(+{diff})</span>
                </span>
            );
        } else if (diff < 0) {
            return (
                <span className="mini digit arrow-container">
                    <span className="red arrow" />
                    <span >(-{Math.abs(diff)})</span>
                </span>
            )
        } else {
            return (
                <span className="mini digit">-</span>
            );
        }
    }

    renderMediumTracker() {

    }

    renderMiniTracker() {
        return (
            <table>
                <tbody>
                <tr className="padless">
                    <td className="center value yellow" colSpan="3">
                        {this.renderTrackerTitle()}
                    </td>
                </tr>
                <tr className="padless">
                    <th width="32%" className="center chunky">{this.props.showClassRankInsteadOfAscendancyRank ? 'CR' : 'AR'}</th>
                    <th width="36%" className="center chunky">Rank</th>
                    <th width="32%" className="center chunky">Change</th>
                </tr>
                <tr className="padless">
                    <td className="center value midly yellow">{this.renderClassRank()}</td>
                    <td className="center value bigly yellow">{this.props.model.get('rank') || '-'}</td>
                    <td className="center value midly">{this.renderRankChange()}</td>
                </tr>
                </tbody>
            </table>
        );
    }

    renderMediumTracker() {
        return (
            <table>
                <tbody>
                <tr className="padless">
                    <td className="center value yellow" colSpan="3">
                        {this.renderTrackerTitle()}
                    </td>
                </tr>
                <tr className="padless">
                    <th width="32%" className="center chunky">{this.props.showClassRankInsteadOfAscendancyRank ? 'CR' : 'AR'}</th>
                    <th width="36%" className="center chunky">Rank</th>
                    <th width="32%" className="center chunky">Change</th>
                </tr>
                <tr className="padless">
                    <td className="center value midly yellow">{this.renderClassRank()}</td>
                    <td className="center value bigly yellow">{this.props.model.get('rank') || '-'}</td>
                    <td className="center value midly">{this.renderRankChange()}</td>
                </tr>
                <tr className="padless">
                    <th width="32%" className="center chunky">{this.getBehindRank()}</th>
                    <th width="36%" className="center chunky">{'Depth'}</th>
                    <th width="32%" className="center chunky">{this.getAheadRank()}</th>
                </tr>
                <tr className="padless">
                    <td className="center value midly behind yellow">{this.props.model.get('behind_depth')}</td>
                    <td className="center value midly xp-per-hour">{this.props.model.get('dead') ? this.renderSkull() : this.props.model.get('solo_depth')}</td>
                    <td className="center value midly ahead yellow">{this.props.model.get('ahead_depth')}</td>
                </tr>
                </tbody>
            </table>
        );
    }

    renderTracker(size) {
        if (size === 1) {
            return this.renderMiniTracker();
        } else {
            return this.renderMediumTracker();
        }
    }

    render() {
        if (!this.state.updated) {
            return <div>Booting up ...</div>;
        }

        let classes = ['tracker', 'medium'];

        if (this.props.fixed) {
            classes.push('fixed');
        }

        return (
            <div className={classes.join(' ')}>
                {this.renderTracker(this.props.size)}
            </div>
        );
    }
}

DelveTracker.defaultProps = {
    model: new Backbone.Model()
}

export default DelveTracker;