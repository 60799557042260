import React from 'react';
import io from 'socket.io-client';
import config from './config.jsx';

import TrackerModel from "./TrackerModel.jsx";

import DelveTracker from "./DelveTracker.jsx";
import Tracker from "./Tracker.jsx";

import './App.css';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: new TrackerModel(null, config),
            socket: io.connect(config.websocket, {
                forceNew: true,
                upgrade: false,
                secure: true,
                transports: ['websocket']
            })
        };

        const params = this.getQueryParams();

        this.state.model.characterParam = params.character || params.name || null;
        this.state.model.eventParam = params.event || null;
        this.state.model.sizeParam = params.size ? parseInt(params.size) : 2;
        this.state.model.specialParam = this.convertSpecialParam(params.special);
        this.state.model.objectiveParam = this.convertObjectiveParam(params.objective);
    }

    componentDidMount() {
        this.setupWebsocketHandlers();
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
    }

    convertSpecialParam(special) {
        if (!special) return null;

        if (special !== 'EXPERIENCE' && special !== 'OBJECTIVES' && special !== 'DEPTH') return null;

        if (special === 'EXPERIENCE') return null;

        return special.toLowerCase();
    }

    convertObjectiveParam(objective) {
        if (!objective) return 'EXPERIENCE';

        if (objective !== 'EXPERIENCE' && objective !== 'POINTS') return 'EXPERIENCE';

        return objective;
    }

    setupWebsocketHandlers() {
        this.state.socket.on('reconnect', () => {
            setTimeout(() => {
                this.onUpdateTracker();
            }, 5000);
        });

        this.state.socket.on('tracker data', this.onTrackerUpdateReceived.bind(this));
    }

    onTrackerUpdateReceived(data) {
        if (data) {
            if (data.list) {
                this.state.model.list.reset(data.list);
            }

            if (data.entry) {
                this.state.model.set(data.entry);
                this.state.model.trigger('found');
            }
        } else {
            this.state.model.trigger('not-found');
        }
    }

    onUpdateTracker(options = {}) {
        if (options.reset) {
            this.state.socket.emit('tracker', null);
        } else {
            this.state.socket.emit('tracker', this.state.model.getEventData())
        }
    }

    getQueryParams() {
        let params = {},
            data = window.location.search.substr(1),
            segments = data.split('&');

        segments.forEach((segment) => {
            let pair = segment.split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        });

        return params;
    }

    isValidTracker() {
        const queryParam = window.location.search;

        return queryParam.indexOf('event=') >= 0 &&
            (queryParam.indexOf('name=') >= 0 || queryParam.indexOf('character=') >= 0);
    }


    renderMissingQueryParams() {
        const queryParam = window.location.search;

        let missingParams = [];

        if (queryParam.indexOf('event') === -1) {
            missingParams.push('event');
        }

        if (queryParam.indexOf('character') === -1 && queryParam.indexOf('name') === -1) {
            missingParams.push('character');
        }

        const missingQueryParams = missingParams.map((param, idx) => {
            return (
                <span style={{ fontSize: '11px'}}>
                    <span style={{ color: '#ddd'}}>{param}</span>
                    {idx !== missingParams.length - 1 ? <span>{idx === missingParams.length - 2 ? ' and ' : ', '}</span> : null}
                </span>
            );
        });

        return (
            <div className="missing-params" style={{ margin: '5px 0'}}>
                {missingQueryParams}
            </div>
        );
    }

    render() {
        const fixed = this.getQueryParams().hasOwnProperty('fixed');

        let delveBypass = false;

        if (this.getQueryParams().hasOwnProperty('delve') && !this.getQueryParams().hasOwnProperty('size')) {
            delveBypass = true;
        }

        if (!this.isValidTracker() && !delveBypass) {
            return (
                <div style={{ color: '#999', fontSize: '12px' }}>
                    <div style={{ fontWeight: 'bold', marginBottom: '10px'}}>Missing query parameters:</div>
                    {this.renderMissingQueryParams()}
                </div>
            );
        }

        if (this.state.model.specialParam === 'depth') {
            return (
                <DelveTracker
                    mainModel={this.state.model}
                    fixed={fixed}
                    size={this.state.model.sizeParam && this.state.model.sizeParam > 2 ? 2 : this.state.model.sizeParam}
                />
            );
        } else {
            return (
                <Tracker
                    model={this.state.model}
                    fixed={fixed}
                    showClassRankInsteadOfAscendancyRank={this.state.model.eventParam && this.state.model.eventParam.indexOf('Class Gauntlet') !== -1}
                    onUpdateTracker={this.onUpdateTracker.bind(this)}
                />
            );
        }
    }
}

export default App;
