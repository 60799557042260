import Backbone from 'backbone';

let TrackerModel = Backbone.Model.extend({
    list: new Backbone.Collection(),
    characterParam: null, // name of character
    eventParam: null, // name of ladder
    sizeParam: 3,
    specialParam: null,

    initialize(attributes, options) {
        this.config = options.config;

        this.list.on('reset', () => {
            this.trigger('change');
        });
    },

    getBehindXP() {
        let targetModel = this.list.findWhere({ name: this.characterParam }),
            cIdx = this.list.indexOf(targetModel),
            previous = cIdx >= 0 ? this.list.at(cIdx + 1) : null; // Set 'character behind' to null.

        return (previous) ? this.renderExp(previous.get('experience') - this.get('experience')) : '?';
    },

    getAheadXP() {
        let targetModel = this.list.findWhere({ name: this.characterParam }),
            cIdx = this.list.indexOf(targetModel),
            next = (cIdx > 0) ? this.list.at(cIdx - 1) : this; // Set 'character ahead' to own model.

        return (next) ? this.renderExp(next.get('experience') - this.get('experience')) : '?';
    },

    getXPH() {
        return this.renderExp(
            Math.floor(this.get('experience_per_hour')),
            true
        );
    },

    renderExp(experience, ignoreOperators = false) {
        let negative = experience < 0,
            xp = Math.abs(experience);

        if(xp >= 1000000000) {
            return (ignoreOperators ? '' : ((negative ? '-' : '+') + ' ')) + (xp / 1000000000).toFixed(0) + 'B';
        } else if(xp >= 1000000) {
            return (ignoreOperators ? '' : ((negative ? '-' : '+') + ' ')) + (xp / 1000000).toFixed(0) + 'M';
        } else if(xp >= 1000) {
            return (ignoreOperators ? '' : ((negative ? '-' : '+') + ' ')) + (xp / 1000).toFixed(0) + 'K';
        } else if(xp > 0) {
            return (ignoreOperators ? '' : ((negative ? '-' : '+') + ' ')) + (xp).toFixed(0);
        } else {
            return '0';
        }
    },

    getEventData() {
        return {
            name: this.characterParam,
            event: this.eventParam,
            size: this.sizeParam,
            special: this.specialParam
        };
    },

    parse(data) {
        if (!data) {
            return null;
        }

        this.list.reset(data.list);

        return data.entry;
    }
});

export default TrackerModel;